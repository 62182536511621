import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";

import Swiper, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Thumbs,
  Mousewheel,
  Parallax,
} from "swiper";

import { Animated } from "react-animated-css";
import PopupForm from "../components/popupForm";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";

import PopupFormDownloadBrochure from "../components/popupFormDownloadBrochure";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Scrollbar,
  Thumbs,
  Mousewheel,
  Parallax,
]);
var accItem;

class studentFinancing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 90000000,
    };
  }

  handlePopupWindow = () => {
    this.setState({ time: Math.random() });
  };

  componentDidMount() {
    document.getElementById("speak-to-an-expert").onclick = function () {
      document.getElementById("book-an-appointment").click();
    };

    accItem = document.getElementsByClassName("accordionItem");
    let accHD = document.getElementsByClassName("accordionItemHeading");
    for (let i = 0; i < accHD.length; i++) {
      accHD[i].addEventListener("click", this.toggleItem, false);
    }
  }

  accorfn = () => {
    accItem = document.getElementsByClassName("accordionItem");
    let accHD = document.getElementsByClassName("accordionItemHeading");
    for (let i = 0; i < accHD.length; i++) {
      accHD[i].addEventListener("click", this.toggleItem, false);
    }
  };
  toggleItem() {
    var itemClass = this.parentNode.className;
    for (let i = 0; i < accItem.length; i++) {
      accItem[i].className = "accordionItem close";
    }
    if (itemClass == "accordionItem close") {
      this.parentNode.className = "accordionItem open";
    }
  }

  render() {
    const post = this.props.data.allWordpressPage.nodes[0];
    const metatitle = post.acf.meta_title;
    const metadescription = post.acf.meta_description;
    const focus_keyphrase = post.acf.focus_keyphrase;
    const canonical_url = `https://theworldgrad.com${this.props.path}`;
    var headerBackground =
      "/static/americanbanner-31161936b50af35a68b688be75f818f4.png";
    if (post.featured_media) {
      headerBackground = post.featured_media.source_url;
    }
    return (
      <div className="about-page">
        <PopupForm time={this.state.time} />
        <Layout>
          <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription} />
            {(() => {
              if (
                post.acf.focus_keyphrase != "" &&
                post.acf.focus_keyphrase != null
              ) {
                return <meta name="keywords" content={focus_keyphrase} />;
              }
            })()}
            <link rel="canonical" href={canonical_url} />
            {(() => {
              if (
                post.acf.dynamic_schema != "" &&
                post.acf.dynamic_schema != null
              ) {
                return (
                  <script type="application/ld+json">
                    {JSON.stringify(JSON.parse(post.acf.dynamic_schema))}
                  </script>
                );
              }
            })()}
          </Helmet>

          <div class="newStyle font-poppins student-financing">
            <div className="container">
              <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
                <li class="breadcrumb-item">
                  <a href="/">Home </a>
                </li>
                <li class="breadcrumb-item active"> {post.title}</li>
              </ol>
            </div>
            <section
              class="wow animate__fadeIn bg-light-gray padding-120px-tb sm-padding-100px-tb position-relative bg-cover480br extra-big-section"
              style={{ backgroundImage: "url(" + headerBackground + ")" }}
            >
              <div class="opacity-extra-medium bg-medium-slate-blue"></div>
              <div class="container">
                <div class="row">
                  <div class="col-12 col-xl-10 col-lg-10 col-md-10 position-relative page-title-large ">
                    <h1 class="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30  margin-10px-bottom">
                      {post.title}
                    </h1>
                    <div
                      class="alt-font text-white no-margin-bottom"
                      dangerouslySetInnerHTML={{
                        __html: post.acf.banner_detail,
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section class="half-section wow animate__fadeIn">
              <div class="container">
                <div class="row ">
                  <div class="col-12 text-center col-lg-12 col-sm-12">
                    <h2>{post.acf.section_1_title}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: post.acf.section_1_detail,
                      }}
                    />
                    <div class="center-button-off">
                      <PopupFormDownloadBrochure
                        programType="Undergraduate"
                        country="Australia, UK, UAE"
                        sourcePage="StudentFinancing"
                        brochureLink={
                          post.acf.download_brochure_new?.source_url
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="half-section bg-light-gray wow animate__fadeIn">
              <div class="container">
                <div class="row">
                  <div class="col-md-10 offset-md-1 text-center margin-two-bottom">
                    <h2>{post.acf.loan_highlights_title}</h2>
                  </div>
                </div>
                <div class="row justify-content-center">
                  {post.acf.loan_highlights.map((item, index) => (
                    <div className="col-12 col-lg-6 col-md-9 margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn">
                      <div className="feature-box br-15 h-100 feature-box-left-icon bg-white box-shadow-small box-shadow-extra-large-hover overflow-hidden padding-2-rem-all">
                        <div className="feature-box-content last-paragraph-no-margin text-justify">
                          <div
                            class="text-center"
                            style={{ paddingBottom: "15px" }}
                          >
                            <img src={item.icon.source_url} alt="✓" />
                          </div>

                          <p className="alt-font font-weight-400 margin-10px-bottom d-block text-center text-extra-dark-gray font-size-18">
                            {item.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            <section class="half-section">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-12 col-xl-6 col-lg-6 col-md-10 md-margin-50px-bottom">
                    <div class="col-12 p-0 margin-3-rem-bottom wow animate__slideInLeft">
                      <h2>{post.acf.documentation_required_title}</h2>
                    </div>
                    <div class="col-12 p-0">
                      {post.acf.documentation_required.map((item, index) => (
                        <div
                          class="col-12 p-0 process-step-style-02 process-step-style-02-without-number  wow animate__fadeIn"
                          data-wow-delay="0.2s"
                        >
                          <div class="process-step-item">
                            <div class="process-step-icon-wrap">
                              <div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight- 500"></div>
                              <span class="process-step-item-box-bfr bg-extra-dark-gray-2"></span>
                            </div>
                            <div class="process-content last-paragraph-no-margin">
                              <span class="alt-font d-block font-weight-400 text-extra-dark-gray margin-5px-bottom">
                                {item.title}
                              </span>
                              <div
                                class="w-80 xs-w-100"
                                dangerouslySetInnerHTML={{
                                  __html: item.detail,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}

                      {(() => {
                        if (post.acf.cta_url === "#popup") {
                          return (
                            <a
                              data-animation="animated zoomInUp"
                              class="btn btn-theme effect btn-md center-button-110 modal-popup"
                              id="speak-to-an-expert"
                              href="#get-in-touch"
                            >
                              {post.acf.cta_title}
                            </a>
                          );
                        } else {
                          return (
                            <a
                              data-animation="animated zoomInUp"
                              class="btn btn-theme effect btn-md center-button-110"
                              href={post.acf.cta_url}
                            >
                              {post.acf.cta_title}
                            </a>
                          );
                        }
                      })()}
                    </div>
                  </div>

                  <div
                    class="animate__slideInRight animated bg-cover br-15 col-12 col-lg-6 margin15 mt-5 mt-lg-0  mx-0 p-0 position-relative wow"
                    data-wow-delay="0.1s"
                    style={{
                      backgroundImage:
                        "url(" +
                        post.acf.documentation_required_image.source_url +
                        ")",
                    }}
                  >
                    <img
                      src={post.acf.documentation_required_image.source_url}
                      alt="Documents"
                      data-no-retina=""
                      class="br-15 margin15 opacity-0 "
                    />
                    <div class="opacity-very-light bg-medium-slate-blue br-15 "></div>
                  </div>
                </div>
              </div>
            </section>
            <section class="half-section scholarship-block bg-fast-yellow ">
              <div class="container">
                <div class="row ">
                  <div class="col-12  text-center  ">
                    <div
                      class="no-margin-bottom sm-margin-20px-top"
                      dangerouslySetInnerHTML={{
                        __html: post.acf.scholarships_content,
                      }}
                    />

                    <p class="margin-10px-bottom margin-10px-bottom">
                      {post.acf.scholarships_text_before_number + " "}
                      <br class="d-show-991" />
                      <CountUp
                        start={1}
                        end={post.acf.scholarships_number}
                        redraw={true}
                        separator=","
                        duration={1.5}
                        formatter={(value) => value.toLocaleString()}
                        delay={1.25}
                      >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef}></span>
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <span>
                        {" "}
                        {" " + post.acf.scholarships_text_after_number}
                      </span>
                    </p>

                    <a
                      data-animation="animated zoomInUp"
                      class="btn btn-theme effect btn-theme-rev btn-theme-rev-orng btn-md   margin-10px-tb"
                      href={post.acf.scholarships_button_link}
                    >
                      {post.acf.scholarships_button}
                    </a>
                    <p class="small">{post.acf.scholarships_small_text}</p>
                  </div>
                </div>
              </div>
            </section>

            <section class="half-section    loan-information  wow animate__fadeIn grayfaq">
              <div class="container">
                <div className="row">
                  <div className="col-12">
                    <h2 className="text-center">
                      {post.acf.loan_information_title}
                    </h2>
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-12 col-lg-12 col-md-10">
                    <Animated
                      animationIn="fadeInUp"
                      animationOut="fadeInDown"
                      isVisible={true}
                    >
                      {post.acf.loan_information.map((item, index) => (
                        <Accordion
                          defaultExpanded={false}
                          className="customize-accord panel bg-white box-shadow-small border-radius-5px margin-30px-bottom"
                        >
                          <AccordionSummary
                            expandIcon={
                              <svg
                                class="MuiSvgIcon-root"
                                focusable="false"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                              >
                                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                              </svg>
                            }
                            className="customize-accord-common customize-accord-title-title"
                          >
                            {item.title}
                          </AccordionSummary>
                          <AccordionDetails className="customize-accord-body-body">
                            <div
                              class="w-100"
                              dangerouslySetInnerHTML={{ __html: item.detail }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Animated>
                  </div>
                </div>
              </div>
              <p class="text-center"></p>
            </section>

            <section class="half-section   wow animate__fadeIn bg-light-gray ">
              <div class="container">
                <div className="row">
                  <div className="col-12">
                    <h2 className="text-center">{post.acf.faq_title}</h2>
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-12 col-lg-12 col-md-10">
                    <Animated
                      animationIn="fadeInUp"
                      animationOut="fadeInDown"
                      isVisible={true}
                    >
                      {post.acf.faqs.map((item, index) => (
                        <Accordion
                          defaultExpanded={false}
                          className="customize-accord panel bg-white box-shadow-small border-radius-5px margin-30px-bottom"
                        >
                          <AccordionSummary
                            expandIcon={
                              <svg
                                class="MuiSvgIcon-root"
                                focusable="false"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                              >
                                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                              </svg>
                            }
                            className="customize-accord-common customize-accord-title-title"
                          >
                            {item.title}
                          </AccordionSummary>
                          <AccordionDetails className="customize-accord-body-body">
                            <div
                              dangerouslySetInnerHTML={{ __html: item.detail }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Animated>
                  </div>
                </div>
              </div>
              <p class="text-center"></p>
            </section>
          </div>
        </Layout>
      </div>
    );
  }
}
export default studentFinancing;

export const StudentFinancingPage = graphql`
  {
    allWordpressPage(filter: { wordpress_id: { in: 7016 } }) {
      nodes {
        title
        wordpress_id
        featured_media {
          source_url
        }
        acf {
          meta_title
          meta_description
          focus_keyphrase
          canonical_url
          dynamic_schema
          banner_detail
          section_1_title
          section_1_detail
          download_brochure_new {
            source_url
          }
          loan_highlights_title
          loan_highlights {
            icon {
              id
              source_url
            }
            title
          }
          documentation_required_title
          documentation_required_image {
            source_url
          }
          documentation_required {
            title
            detail
          }
          cta_title
          cta_url
          loan_information_title
          loan_information {
            title
            detail
          }
          scholarships_content
          scholarships_text_before_number
          scholarships_number
          scholarships_text_after_number
          scholarships_button
          scholarships_button_link
          scholarships_small_text
          scholarships_image {
            source_url
          }
          faq_title
          faqs {
            title
            detail
          }
        }
      }
    }
  }
`;
